import React from 'react';
import { useIntl } from 'react-intl';
import { SVGIcon } from './svgHelpers';

export const Installments: SVGIcon = (props) => {
  const { className, ...otherProps } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'Installments Payment Plan Icon',
    description: 'Installments Payment Plan Icon',
  });

  return (
    <svg
      className={`fill-current ${className}`}
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="installments-payment-plan-icon"
      {...otherProps}
    >
      <title id="installments-payment-plan-icon">{title}</title>
      <g>
        <path d="m28.3778 30c-.434 0-.868-.1667-1.1852-.5l-7.2111-7.3334c-.9849-.8666-1.6192-2.2333-1.6192-3.6666v-10.16671c0-.91666.7512-1.66666 1.6693-1.66666.918 0 1.6692.75 1.6692 1.66666v10.15001c0 .4833.2003.9333.5676 1.25l7.2946 7.4333c.651.65.6343 1.7167-.0167 2.35-.3172.3334-.7512.4834-1.1685.4834z" />
        <path d="m20.0316 3.33337c-.4507 0-.8681-.16667-1.1852-.48333-.1669-.16667-.2838-.33334-.3672-.55-.0835-.2-.1169-.41667-.1169-.63334 0-.43333.1669-.866663.4841-1.166664.4507-.4666663 1.2019-.633333 1.8195-.366666.2003.083333.3839.2.5341.366666.3172.300001.5008.733334.5008 1.166664 0 .21667-.0501.43334-.1335.63334-.0835.21666-.2003.38333-.3673.55-.3171.31666-.7344.48333-1.1684.48333z" />
        <path d="m37.1245 26.9173c-.9014-.2667-1.3854-1.2-1.1184-2.0834.2671-.8666 1.2019-1.3666 2.0699-1.1.8847.2667 1.3855 1.2 1.1184 2.0667-.217.7333-.8847 1.2-1.6025 1.2-.1502 0-.3171-.0333-.4674-.0833zm-.4507-8.2167c-.0834-.9167.601-1.7333 1.5357-1.8.9181-.0667 1.7194.6167 1.7861 1.5333.0668.9167-.6176 1.7167-1.5357 1.8-.05 0-.0834 0-.1335 0-.8513 0-1.6025-.6666-1.6526-1.5333zm-1.6191-5.9667c-.4007-.8333-.0668-1.8333.7678-2.2333s1.8195-.05 2.2368.7667c.4006.8333.0501 1.8333-.7845 2.2333-.2337.1167-.4841.1667-.7178.1667-.6176 0-1.2186-.35-1.5023-.9334zm-3.7058-4.96663c-.6677-.63333-.7177-1.68333-.0834-2.34999.6176-.68334 1.6859-.71667 2.3536-.1.6844.63333.7178 1.68333.1002 2.34999-.3339.36667-.7846.55-1.2353.55-.4006 0-.8012-.14999-1.1351-.45zm-5.2414-3.28333c-.8513-.33333-1.2853-1.3-.9348-2.16667.3339-.85 1.302-1.28333 2.1533-.93333.868.33333 1.2854 1.3.9515 2.15-.2671.66667-.8847 1.06667-1.5524 1.06667-.2003 0-.4173-.05-.6176-.11667z" />
        <path d="m34.4378 33.0335c-.4507 0-.868-.1666-1.1852-.4833-.1502-.1667-.2837-.3333-.3672-.55-.0835-.2-.1168-.4167-.1168-.6333 0-.2167.0333-.4334.1168-.6334s.217-.3833.3672-.55c.1503-.15.3339-.2666.5342-.35.3172-.1333.651-.1666.9682-.1.1168.0167.217.05.3171.1.1002.0334.2003.0834.2838.15.1002.0667.1836.1334.2504.2.3171.3167.5008.75.5008 1.1834 0 .2333-.0501.4333-.1336.6333-.0834.2167-.2003.3833-.3672.55-.3005.3167-.7178.4833-1.1685.4833z" />
        <path d="m20.031 40c-11.05043 0-20.031-8.9667-20.031-20 0-11.03333 8.98057-20 20.031-20 .9181 0 1.6693.75 1.6693 1.66667 0 .91666-.7512 1.66666-1.6693 1.66666-9.1976 0-16.6925 7.48337-16.6925 16.66667s7.4949 16.6667 16.6925 16.6667c5.1413 0 9.9154-2.3 13.1036-6.3334.5676-.7166 1.6192-.85 2.337-.2666.7178.5666.8513 1.6166.2671 2.3333-3.8226 4.8333-9.5482 7.6-15.7077 7.6z" />
      </g>
    </svg>
  );
};
