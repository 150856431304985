import { useIntl } from 'react-intl';
import { Money } from '@ads-bread/shared/bread/codecs';
import { isMoney } from '@ads-bread/shared/bread/util';
import { percentFormat } from '../format-number';

export type FormatCurrency = (value: number | Money) => string;

type UseFormattedNumber = {
  /**
   * Converts a Money value to a formatted number string
   * @param amount - The Money object to format
   * @returns a formatted string representation of the Money value
   * @example ```
   * formatCurrency({value: 200000, currency: 'USD')
   * ``` returns "2,000"
   */
  formatCurrency: FormatCurrency;
  aprFormatter: (bps: number) => string;
};

/**
 * Returns a function that converts Money value to a formatted number string. *
 * @returns a formatted string representation of the Money value.
 */
export function useFormattedNumber(): UseFormattedNumber {
  const intl = useIntl();

  const formatCurrency: FormatCurrency = (amount) => {
    const value = isMoney(amount) ? amount.value : amount;
    const result = intl.formatNumber(value / 100, {
      style: 'currency',
      // Hard-coded to USD because both our tenants use USD formatting
      // without the prefix of `CA` for Canadian users
      currency: 'USD',
    });
    return result.replace(/([A-Za-z])+/, '');
  };

  const aprFormatter = (bps: number): string => {
    return intl.formatNumber(bps / 10000, percentFormat);
  };

  return { aprFormatter, formatCurrency };
}
