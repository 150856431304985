import React from 'react';
import { FCWithChildren } from '../lib/types';
import { SVGIcon } from './svgs/svgHelpers';

interface IntroCardProps {
  icon: SVGIcon;
  dataTestId: string;
  className?: string;
}

export const IntroCard: FCWithChildren<IntroCardProps> = ({
  children,
  icon: Icon,
  dataTestId,
  className,
}) => {
  return (
    <div
      className={`relative rounded-sm text-center px-4 py-4 mt-5 shadow ${className}`}
      data-testid={dataTestId}
    >
      <div className="absolute top-0 left-0 h-1 w-full rounded-t-sm bg-theme-primary" />
      <div className="bg-white absolute top-0 left-1/2 transform-gpu -translate-y-1/2 -translate-x-1/2 px-2">
        <Icon aria-hidden="true" className="text-theme-primary" />
      </div>
      {children}
    </div>
  );
};
