import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import { useCloseModal } from '../lib/hooks/useCloseModal';
import { useMerchantConfig } from './MerchantContext';
import { useSDKCallbacks } from './XPropsContext';

export const MarketingPromo: FunctionComponent = () => {
  const {
    marketingDescription,
    marketingImageUrl,
    marketingUrl,
    marketingCardPlacementID,
  } = useMerchantConfig();
  const { triggerOpenCardModal } = useSDKCallbacks();
  const { closeModalByContext } = useCloseModal();

  const parsedBody = parse(marketingDescription || '');

  if (!parsedBody || !marketingImageUrl) {
    return null;
  }

  return (
    <div className="flex mb-6 mt-2">
      <img src={marketingImageUrl} className="h-12 mr-4" alt="Logo" />
      <div>
        <p className="inline" data-testid="marketing-promo-body">
          {parsedBody}
        </p>
        <button
          className="text-base font-bold cursor-pointer text-theme-primary ml-1"
          data-testid="marketing-learn-more-link"
          onClick={() => {
            if (!marketingCardPlacementID) {
              marketingUrl && global.open(marketingUrl);
            } else {
              closeModalByContext && closeModalByContext();
              triggerOpenCardModal &&
                triggerOpenCardModal({
                  placementId: marketingCardPlacementID,
                });
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Learn More"
            description="Learn more marketing link text"
          />
        </button>
      </div>
    </div>
  );
};
